<template>
  <div class="explore-company-info">
    <div v-if="info?.shortTitle" class="explore_company_item">
      <span class="_label"> Наименование: </span>
      <span class="_value"> {{ info.shortTitle }} </span>
    </div>

    <div v-if="info?.fullTitle" class="explore_company_item">
      <span class="_label"> Полное наименование: </span>
      <span class="_value"> {{ info.fullTitle }} </span>
    </div>

    <div v-if="info?.inn" class="explore_company_item">
      <span class="_label"> ИНН: </span>
      <span class="_value"> {{ info.inn }} </span>
    </div>

    <div v-if="info?.kpp" class="explore_company_item">
      <span class="_label"> КПП: </span>
      <span class="_value"> {{ info.kpp }} </span>
    </div>

    <div v-if="info?.postalAddress" class="explore_company_item">
      <span class="_label"> Почтовый адрес: </span>
      <span class="_value"> {{ info.postalAddress }} </span>
    </div>

    <div v-if="info?.legalAddress" class="explore_company_item">
      <span class="_label"> Юридический адрес: </span>
      <span class="_value"> {{ info.legalAddress }} </span>
    </div>

    <div v-if="info?.realAddress" class="explore_company_item">
      <span class="_label"> Фактический адрес: </span>
      <span class="_value"> {{ info.realAddress }} </span>
    </div>

    <template v-if="info?.contactPersons">
      <div
        v-for="person in info.contactPersons.slice(0, !isOpen ? 3 : info.contactPersons.length)"
        class="explore_company_item"
      >
        <span class="_label"> Контактное лицо: </span>
        <span class="_value">
          <template v-if="person.fullName"> {{ person.fullName }} &nbsp;</template>
          <template v-if="person.phone"><span class="contact-label">Телефон:</span> {{ person.phone }} &nbsp;</template>
          <template v-if="person.email"><span class="contact-label">Email:</span> {{ person.email }}</template>
        </span>
      </div>
      <div class="explore_company_item" v-if="info?.contactPersons.length > 3">
        <span class="_label"></span>
        <span class="_value link" @click="isOpen = !isOpen">
          {{ !isOpen ? 'Показать все данные' : 'Скрыть данные'}}
        </span>
      </div>
    </template>

    <div class="explore_company_buttons">
      <slot name="action" :info="info" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import type { PropType } from "vue";

import type { CompanyInfoInterface } from "@/stores/manuals/ManualsInterface";

export default defineComponent({
  name: "ExploreCompanyInfo",
  props: {
    info: {
      type: Object as PropType<CompanyInfoInterface>,
      default: () => ({}),
    },
  },
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/paddings';
@import '@/assets/styles/mixin/links';
@import '@/assets/styles/mixin/fonts';

.link {
  @include link-mixin;
}

.explore-company-info {
  display: flex;
  flex-flow: column;
  gap: 8px;
  @include label-13-16;
}

.explore_company_item {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 8px;

  span._label {
    width: 200px;
    color: var(--main-placeholder-color);
  }

  span.contact-label {
    color: var(--main-placeholder-color);
  }
}

.explore_company_buttons {
  @include elements_with_space;
}
</style>
