<template lang="pug">
.company-wrapper
  .company-selector
    company-selector(
      v-model="selectedCompanyRef"
      :placeholder="placeholder"
      @select:clear="resetSelect"
      @select:change="setSelectedCompany"
    )
    ui-button.nowrap(
      :icon-right="showAnalyseForm ? UiIconNames.Chevron_Up : UiIconNames.Chevron_Down"
      @click="showAnalyseForm = !showAnalyseForm"
    ) Расширенный анализ

  alert-message(v-if="showAlert" :label="alertData.title" :message="alertData.message" :type="alertData.type")
  alert-message(v-if="isAdvancedModified && !showAnalyseForm" label="Выбраны расширенные фильтры" type="warning")

  ui-loader(v-if="isCompanyChecking")

  search-form(
    v-if="showAnalyseForm"
    :tab="tab"
    v-model:searchForm="searchForm"
    v-model:show-search-form="showAnalyseForm"
    v-model:activeTab="searchFormActiveTab"
    @start:analyse="startAnalyse"
    @hide:search-form="showAnalyseForm = false"
  )

  about-explore-company(
    :data="selectedCompanyDetails"
    :tab="tab"
    :selected-company="selectedCompany"
  )
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { scrollToTop, scrollIntoView } from "@/utils/scroller/documentScroller";
import { useVModel } from "@vueuse/core";
import { useApi } from "@/use/api/useApi";
import { getDefaultSearchForm } from "@/utils/getters/defaultSearchForm";
import type { PropType } from "vue";
import type { TabMode } from "@/stores/search/SearchFormInterface";
import type { BaseSearchFormInterface } from "@/stores/search/SearchFormInterface";
import type { SingleCompanyInterface } from "@/stores/manuals/ManualsInterface";

import CompanySelector from "@/components/selector/CompanySelector.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";
import AboutExploreCompany from "@/components/pages/analytics/exploreCompany/AboutExploreCompany.vue";
import SearchForm from "@/components/searchForm/SearchForm.vue";
import UiLoader from "@/components/ui/loader/UiLoader.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

import omit from "lodash/omit";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "CompanyWrapper",
  components: {
    UiButton,
    UiLoader,
    SearchForm,
    AboutExploreCompany,
    AlertMessage,
    CompanySelector,
  },
  emits: [
    'reset:all',
    'reset:data',
    'reset:form',
    'fetch:analyse',
    'update:showAnalytics',
    'update:searchForm',
    'update:selectedCompany',
  ],
  props: {
    selectedCompany: {
      type: Object as PropType<SingleCompanyInterface>,
      default: () => ({}),
    },
    searchForm: {
      type: Object as PropType<BaseSearchFormInterface>,
      default: () => ({}),
    },
    showAnalytics: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    tab: {
      type: String as PropType<Exclude<TabMode, 'LotsListSearch'|'AnalyseIndustry'>>,
      default: '',
    },
  },
  setup(props, context) {

    const router = useRouter();
    const route = useRoute();

    const searchFormRef = useVModel(props, 'searchForm', context.emit)
    const selectedCompanyRef = useVModel(props, 'selectedCompany', context.emit)

    const selectedCompanyDetails = ref<any[]>([])

    const showAlert = ref(false);
    const showAnalyseForm = ref(false);

    const searchFormActiveTab = ref('default');

    const isCompanyChecking = ref(false);

    const alertData = ref({ title: '', message: '', type: 'info' })

    const emptyCompanyAlert = {
      title: 'Компания не выбрана',
      message: 'Вам необходимо выбрать компанию из списка. Для этого начните вводить название, адрес, ИНН или КПП в' +
        ' селектор и выберите нужную компанию из списка. При необходимости, Вы можете уточнить запрос, воспользовавшись расширенными фильтрами',
      type: 'error',
    }

    const notExistCompanyAlert = {
      title: 'Компания не найдена',
      message: 'К сожалению, выбранная компания отсуствует в нашей базе. Пожалуйста, выберите другую компанию.',
      type: 'error',
    }

    const kppError = {
      title: 'Некорректный запрос',
      message: 'КПП не должен быть пустым. Укажите конкретный КПП для филиала, или задайте фильтр "Выводить все филиалы" для поиска компаний по ИНН, без учета КПП.',
      type: 'error',
    }

    const defaultSearchForm = getDefaultSearchForm(props.tab)

    const isAdvancedModified = computed(() => {
      return !isEqual(
        omit(searchFormRef.value, ['inn', 'kpp', 'needAllFilials', 'supplierCompanyId', 'customerCompanyId', 'industrySearchMode', 'wordSearchMode']),
        omit(defaultSearchForm, ['inn', 'kpp', 'needAllFilials', 'supplierCompanyId', 'customerCompanyId', 'industrySearchMode', 'wordSearchMode']),
      )
    })

    const needAllFilials = computed(() => !(route.query.needThisOffice as string === 'true'))

    function setSelectedCompanySF(company: any, needThisOffice: boolean) {
      if (props.tab === 'AnalyseCustomers') searchFormRef.value.customerCompanyId = company.id || undefined;
      if (props.tab === 'AnalyseSuppliers') searchFormRef.value.supplierCompanyId = company.id || undefined;
      searchFormRef.value.inn = company.inn || null;
      searchFormRef.value.kpp = company.kpp || null;
      searchFormRef.value.needAllFilials = !needThisOffice;
    }

    function resetSelect() {
      context.emit('reset:all')
      context.emit('update:showAnalytics', false)
      selectedCompanyDetails.value = []
      showAlert.value = false;
      router.replace({ query: {} });
    }

    function onMount() {
      const company = {
        id: Number(route.query.id as string) || undefined,
        inn: Number(route.query.inn as string) || undefined,
        kpp: Number(route.query.kpp as string) || undefined,
        label: `ИНН: ${ Number(route.query.inn as string) || 'Неизвестен' }, КПП: ${ Number(route.query.kpp as string) || 'Неизвестен' }`,
      }
      const needThisOffice = route.query.needThisOffice as string === 'true';

      if (company.inn || company.kpp || company.id) {
        /** если был переход через роут */
        setCompanyAndRoute(company, needThisOffice)
        fetchData(company, needThisOffice)
      } else if (selectedCompanyRef.value) {
        /** если были сохраненные данные в сторе */
        setCompanyAndRoute(selectedCompanyRef.value, !searchFormRef.value.needAllFilials)
        fetchData(selectedCompanyRef.value, !searchFormRef.value.needAllFilials)
      }
    }

    onMount()

    function fetchData(companyMeta: SingleCompanyInterface, needThisOffice: boolean) {
      checkCompanyExist(companyMeta, needThisOffice)
        .then(() => {
          /** если компания есть в базе */
          showAlert.value = false
          setSelectedCompanySF(companyMeta, needThisOffice)
          context.emit('fetch:analyse')
        })
        .catch(() => {
          /** если компании нет в базе */
          alertData.value = notExistCompanyAlert
          showAlert.value = true
        })
    }

    function setSelectedCompany(company: SingleCompanyInterface, needThisOffice = false, resetForm = true) {
      const isCompanyPrevSelected = !!selectedCompanyRef.value; // не переносить, нужно знать было ли предыдущее значение перед установкой нового

      if (company.id || company.inn || company.kpp) {
        context.emit('update:showAnalytics', false)
        setCompanyAndRoute(company, needThisOffice)
        selectedCompanyDetails.value = []
        showAnalyseForm.value = false;
        showAlert.value = false;
        context.emit('reset:data')
        if (isCompanyPrevSelected && resetForm) context.emit('reset:form')
        fetchData(company, needThisOffice)
      }
    }

    function updateRoute(companyMeta: SingleCompanyInterface | null, needThisOffice: boolean) {
      if (!companyMeta) {
        router.replace({ query: {} })
      } else {
        router.replace({ query: { id: companyMeta?.id || undefined, inn: companyMeta?.inn || undefined, kpp: companyMeta?.kpp || undefined, needThisOffice }} );
      }
    }

    function setCompanyAndRoute(companyMeta: SingleCompanyInterface | null, needThisOffice: boolean) {
      selectedCompanyRef.value = companyMeta;
      updateRoute(companyMeta, needThisOffice);
    }

    /** прверяет есть ли изменения уже выбранной компании и данных в серч форме (инн, кпп и needAllFilials) */
    function hasCompanyChanges() {
      return !isEqual(
        pick(searchFormRef.value, ['inn', 'kpp', 'needAllFilials']),
        {
          inn: selectedCompanyRef.value?.inn || null,
          kpp: selectedCompanyRef.value?.kpp || null,
          needAllFilials: needAllFilials.value,
        })
    }

    function startAnalyse() {
      if (!selectedCompanyRef.value) {
        /** если компания не выбрана */
        alertData.value = emptyCompanyAlert;
        showAlert.value = true;
        scrollToTop();
      } else if (!searchFormRef.value?.kpp && !searchFormRef.value?.needAllFilials) {
        /** если компания выбрана, но кпп не указан, а needAllFilials отсутствует */
        context.emit('update:showAnalytics', false)
        selectedCompanyDetails.value = []
        context.emit('reset:data')
        alertData.value = kppError
        showAlert.value = true
        scrollToTop();
      } else if (hasCompanyChanges()) {
        /** если компания выбрана, но ее данные отличаются от серч формы (инн, кпп, выводить все филиалы) */
        const newCompany = { ...selectedCompanyRef.value, kpp: searchFormRef.value?.kpp || null }
        setSelectedCompany(newCompany, !searchFormRef.value?.needAllFilials, false)
      } else {
        /** если все ок и правили только расширенные фильтры - можно перезапросить данные */
        updateRoute(selectedCompanyRef.value, !searchFormRef.value?.needAllFilials)
        // showAnalyseForm.value = false;
        context.emit('reset:data')
        context.emit('fetch:analyse')
        scrollIntoView("explore-company", "start")
      }
    }

    function checkCompanyExist(companyMeta: SingleCompanyInterface, needThisOffice: boolean) {
      isCompanyChecking.value = true;

      const payload = {
        companyIds: companyMeta?.id ? [companyMeta.id] : [],
        inn: companyMeta?.inn || null,
        kpp: companyMeta?.kpp || null,
        needFilials: !needThisOffice,
      }

      return new Promise((resolve, reject) => {

        useApi().analytics.fetchCompanyExist(payload)
          .then((data) => {
            if (!data) reject(null)
            else {
              context.emit('update:showAnalytics', true)
              selectedCompanyDetails.value = data || []
              resolve(null)
            }
          })
          .catch(() => reject(null))
          .finally(() => isCompanyChecking.value = false)

      })
    }

    return {
      showAlert,
      alertData,
      showAnalyseForm,
      selectedCompanyRef,
      isAdvancedModified,
      isCompanyChecking,
      selectedCompanyDetails,
      searchFormActiveTab,
      resetSelect,
      setSelectedCompany,
      startAnalyse,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
.company-wrapper {
  display: flex;
  flex-flow: column;
  gap: 32px;
}

.company-selector {
  display: flex;
  gap: 8px;

  .ui-select {
    width: 100%;
  }

  .nowrap {
    white-space: nowrap;
  }
}
</style>
