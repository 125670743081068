<template lang="pug">
el-select.ui-select(
  size="large"
  remote
  clearable
  filterable
  fit-input-width
  remote-show-suffix
  value-key="hid"
  no-match-text="Нет совпадений"
  no-data-text="Список пуст"
  :teleported="false"
  :model-value="modelValue"
  :placeholder="placeholder"
  :remote-method="getFilteredCompanies"
  :loading="info.loading"
  @clear="onClear"
  @change="onChange"
)
  el-option(
    v-for="item of data"
    :key="item.label"
    :label="item.label"
    :value="item"
  )
    b {{ item.title }}
    .info
      template(v-if="item.inn") {{ 'ИНН: ' + item.inn }}
      template(v-if="item.kpp") {{ item.inn && ', ' + 'КПП: ' + item.kpp }}
      template(v-if="item.legalAddress") {{ (item.inn || item.kpp) && ', ' + 'Адрес: ' + item.legalAddress }}
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useApi, useAbort } from "@/use/api/useApi";
import { getDefaultRequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

import type { PropType } from "vue";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";
import type { SingleCompanyInterface } from "@/stores/manuals/ManualsInterface";

export default defineComponent({
  name: "CompanySelector",
  emits: [
    'select:clear',
    'select:change',
  ],
  props: {
    modelValue: {
      type: Object as PropType<SingleCompanyInterface>,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const data = ref([])
    const info = ref<RequestCommonInfo>(getDefaultRequestCommonInfo())

    function getFilteredCompanies(query: string) {
      if (!query) return;
      if (info.value.request) info.value.request.cancel();

      const { signal, abort, } = useAbort();

      info.value.request = { cancel: abort };
      info.value.loading = true;
      data.value = []

      useApi().analytics.fetchFilteredCompanies({ query: query, count: 20 }, signal)
        .then((requestData: any) => {
          const modifyData = requestData?.suggestions?.slice(0, 20).map((comp: any) => { return {
            hid: comp.data.hid,
            title: comp.unrestricted_value,
            label: `${ comp.unrestricted_value } (ИНН: ${ comp.data.inn || 'Неизвестен' })`,
            inn: comp.data.inn,
            kpp: comp.data.kpp,
            legalAddress: comp.data.address.data.source,
          }})
          data.value = Object.freeze(modifyData)
          info.value.loading = false;
          info.value.request = null;
        })
        .catch((error) => {
          if (!signal.aborted) {
            info.value.request = null;
            info.value.loading = false;
          }
        })
    }

    function onClear() {
      data.value = [];
      info.value = getDefaultRequestCommonInfo();

      context.emit('select:clear')
    }

    function onChange(value: any) {
      if (value) context.emit('select:change', value)
    }

    return {
      data,
      info,
      onClear,
      onChange,
      getFilteredCompanies,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/selector';

.info {
  font-size: 13px;
}
</style>
