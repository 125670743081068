<template lang="pug">
#explore-company.about_explore_company(v-if="data.length")
  .explore_company_title(v-if="selectedCompany?.title") {{ selectedCompany?.title }}
  el-collapse(v-if="data.length > 1" v-model="activeName" accordion)
    el-collapse-item(v-for="company of data" :key="company.id" :name="company.id")
      template(#title)
        .collapse-title
          div(style="text-align: start") {{ company.shortTitle }}
          div(style="text-align: start")
            span(v-if="company?.inn" style="font-weight: normal") ИНН: {{ company.inn + ' ' }}
            span(v-if="company?.kpp" style="font-weight: normal") КПП: {{ company.kpp }}

      explore-company-info(:info="company")
        template(#action="{ info }")
          analytic-link(:tab="tab === 'AnalyseSuppliers' ? 'suppliers' : 'customers'" :company="info" need-this-office)
            ui-button(type="secondary" style="width: fit-content") Аналитика по филиалу

  explore-company-info(v-else-if="data.length === 1" :info="data[0]")
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import type { PropType } from "vue";
import type { TabMode } from "@/stores/search/SearchFormInterface";
import type { CompanyInfoInterface, SingleCompanyInterface } from "@/stores/manuals/ManualsInterface";

import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import ExploreCompanyInfo from "@/components/pages/analytics/exploreCompany/ExploreCompanyInfo.vue";

export default defineComponent({
  name: "AboutExploreCompany",
  components: {
    UiButton,
    AnalyticLink,
    ExploreCompanyInfo,
  },
  props: {
    tab: {
      type: String as PropType<Exclude<TabMode, 'LotsListSearch'|'AnalyseIndustry'>>,
      default: '',
    },
    data: {
      type: Array as PropType<CompanyInfoInterface[]>,
      default: () => [],
    },
    selectedCompany: {
      type: Object as PropType<SingleCompanyInterface>,
      default: () => {},
    },
  },
  setup() {

    const activeName = ref('1')

    return {
      activeName,
    };
  },
});
</script>

<style scoped lang="scss">
#explore-company {
  :deep(.el-collapse-item__header) {
    height: auto;
    line-height: normal;
    padding: 12px 0;
  }
}

.about_explore_company {

  display: flex;
  flex-flow: column;
  gap: 4px;

  box-sizing: border-box;

  border: 1px solid #D4D7DE;
  border-radius: 4px;

  padding: 12px 16px;
  font-size: 14px;
  line-height: 18px;

  max-height: 544px;
  overflow: auto;
  background-color: white;

  .collapse-title {
    display: flex;
    flex-flow: column;
    align-items: start;
  }

  .explore_company_title {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
  }
}
</style>
